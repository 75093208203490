@font-face {
  font-family: "Readex Regular";
  src: url("./fonts/Readex_Pro/ReadexPro-VariableFont_wght.ttf");
  font-display: swap;
}

:root {
  --bg-default: #eeeeee;
  --bg-dark: #f4f6f8;
  --bg-paper: #fff;

  --bg-disabled-button: rgba(0, 0, 0, 0.12);
  --bg-hover-button: #dde7fa;

  --primary-main-color: #3e8dfb;
  --primary-light-color: #d4e1fa;
  --primary-dark-color: #214eb1;
  --primary-medium-color: #6ca4fc;

  --secondary-main-color: #2b3270;
  --secondary-light-color: #dfe2f8;
  --secondary-dark-color: #17093a;
  --secondary-medium-color: #8891dd;

  --error-main-color: #de1860;
  --error-light-color: #fde1e7;
  --error-dark-color: #931e5c;
  --error-medium-color: #fc4c8f;

  --warning-main-color: #e27964;
  --warning-light-color: #feebd7;
  --warning-dark-color: #ca5649;
  --warning-medium-color: #fcc0a9;

  --info-main-color: #83736f;
  --info-light-color: #ede0cf;
  --info-dark-color: #5a493c;
  --info-medium-color: #bea898;

  --success-main-color: #25d9c5;
  --success-light-color: #ccf2ed;
  --success-dark-color: #0f7373;
  --success-medium-color: #8cebe1;

  --white-transparent-color: rgb(255 255 255 / 74%);
  --black-transparent-35: rgb(0 0 0 / 35%);

  --main-text-color: #000;
  --secondary-text-color: #ffffff;

  --black-transparent-70: rgb(0 0 0 / 70%);
  --black-transparent-50: rgb(0 0 0 / 50%);
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fadeIn-with-transform {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes retrainAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes transcription-scanner {
  from {
    top: 0px;
  }

  to {
    top: 100%;
  }
}

@keyframes transcription-ready-layer {
  from {
    background-color: var(--black-transparent-70);
    transform: scale(1);
    opacity: 1;
  }

  to {
    background-color: var(--black-transparent-70);
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes showIcon {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes start-transcription {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes letter-after-transcription {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes end-transcription {
  from {
    width: 200px;
  }

  to {
    width: 0;
  }
}

@keyframes cursor-flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.snackbar-success-check-icon {
  color: var(--success-medium-color);
}

.SnackbarItem-contentRoot {
  padding: 4px 8px !important;
  min-width: 600px !important;
}

.snackbar-msg {
  color: var(--secondary-text-color) !important;
  padding-inline-start: 4px !important;
}

.snackbar-close-iconButton {
  color: var(--white-transparent-color) !important;
}

.primary-icon {
  fill: var(--primary-main-color) !important;
}

.notistack-MuiContent-info {
  background-color: var(--secondary-main-color) !important;
}

.notistack-MuiContent-success {
  background-color: var(--success-dark-color) !important;
}

å .notistack-MuiContent-error {
  background-color: var(--error-dark-color) !important;
}

.notistack-MuiContent-warning {
  background-color: var(--warning-dark-color) !important;
}

.SnackbarItem-message {
  max-width: 500px !important;
}
.disabled-pagination-buttons-editor {
  color: rgb(139, 186, 252) !important;
  opacity: 0.38;
}
.display-none {
  display: none;
}
:lang(en).display-none {
  display: none;
}
