.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--bg-default);
}

/* Footer.js */
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  border-top: 1px solid #0000001f;
  padding: 0 32px 0 24px;
  margin-top: auto;
}
.footer-links {
  display: flex;
  align-items: center;
}
.footer-link {
  margin: 0 16px !important;
}
